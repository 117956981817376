import { useState } from 'react';
import Papa from 'papaparse';

import { findConflicts, downloadCSV, stringToRgb, isDark } from './utils';
import './App.css';

function App() {
  const HEADERS = ['Class A', 'Class A Signups', 'Class B', 'Class B Signups', 'Common Students Count', 'Common Students'];
  const [filters, setFilters] = useState(HEADERS);
  const [conflicts, setConflicts] = useState([]);
  const [colorCoding, setColorCoding] = useState(true);

  function handleUpload(e) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function(e) {
        const csvString = e.target.result;
        const { data } = Papa.parse(csvString);
        const conflicts = findConflicts(data);
        setConflicts(conflicts);
      };
      reader.readAsText(file);
    }
  }

  function handleDownload() {
    const data = JSON.parse(JSON.stringify(conflicts));

    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (!filters.includes(key)) {
          delete item[key];
        }
      });
    });

    downloadCSV(data);
  }

  function toggleFilter(filter) {
    let index = filters.indexOf(filter);
    if (index > -1) {
      filters[index] = '';
    }
    else {
      index = HEADERS.indexOf(filter)
      filters[index] = filter;
    }

    setFilters([...filters]);
  }

  function getColor(filter, value) {
    if (['Class A', 'Class B'].includes(filter) && colorCoding) {
      return stringToRgb(value);
    }
    else {
      return '#ffffff';
    }
  }

  return (
    <div className="App">
      <h1>Class Conflict Checker</h1>
      <div className="actions">
        <input type="file" onChange={handleUpload} />
        {conflicts.length !== 0 && <button onClick={handleDownload}>Download Conflicts</button>}
      </div>
      <div className="filters">
        <div>
          <h2>Filters</h2>
          {HEADERS.map((header) => (
            <label key={header}>
              <input type="checkbox" checked={filters.includes(header)} onChange={() => toggleFilter(header)} /> {header}
            </label>
          ))}
        </div>
        <div>
          <h2>Options</h2>
          <label>
            <input type="checkbox" checked={colorCoding} onChange={() => setColorCoding(!colorCoding)} /> Toggle Color Coding
          </label>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {filters.map((filter) => (
              !!filter && <th key={filter}>{filter}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {conflicts.map((conflict, i) => (
            <tr key={i}>
              {filters.map((filter) => (
                !!filter && <td key={filter} style={{ backgroundColor: getColor(filter, conflict[filter]), color: isDark(getColor(filter, conflict[filter])) }}>{conflict[filter]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
