import Papa from 'papaparse';

export function findConflicts(data) {
  const courseStudents = {};

  // group students by course
  data.forEach((row, index) => {
    if (index === 0) return;

    let studentId = row[0];

    for (let i = 1; i < row.length; i++) {
      let course = row[i].trim();

      if (course) {
        if (courseStudents[course]) {
          courseStudents[course].push(studentId);
        }
        else {
          courseStudents[course] = [studentId];
        }
      }
    }
  });

  // remove course with students > 68
  for (let course in courseStudents) {
    if (courseStudents[course].length > 68) {
      delete courseStudents[course];
    }
  }

  // find conflicts
  const conflicts = [];
  const courses = Object.keys(courseStudents);

  for (let i = 0; i < courses.length; i++) {
    for (let j = i + 1; j < courses.length; j++) {
      let courseA = courses[i];
      let courseB = courses[j];

      const commonStudents = courseStudents[courseA].filter(studentId => courseStudents[courseB].includes(studentId));

      if (commonStudents.length) {
        conflicts.push({
          'Class A': courseA,
          'Class A Signups': courseStudents[courseA].length,
          'Class B': courseB,
          'Class B Signups': courseStudents[courseB].length,
          'Common Students Count': commonStudents.length || 0,
          'Common Students': commonStudents.join(', '),
        });
      }
    }
  }

  return conflicts;
}


export function downloadCSV(data) {
  const csvData = Papa.unparse(data)

  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = 'conflicts.csv'; 
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}


export function stringToRgb(str){
  if (!str) {
    return '#000000';
  }

  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var c = (hash & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return "#" + "00000".substring(0, 6 - c.length) + c;
}

export function isDark(color) {
  const c = color.substring(1);  
  const rgb = parseInt(c, 16);  
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >>  8) & 0xff;
  const b = (rgb >>  0) & 0xff;

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; 

  if (luma < 70) {
    return '#ffffff';
  }
  else {
    return '#000000';
  }
}

